jQuery('.patents-shortcode').on('click', '.page-numbers', function(e) {
    e.preventDefault();
    let shortcode = jQuery('.patents-shortcode');
    let url = new URL(jQuery(this)[0].href);
    shortcode.load(url + ' .patents-shortcode');
    jQuery('html, body').animate({
        scrollTop: shortcode.position().top
    }, 300);
    window.history.pushState({}, '', url);
});
